import Icon from './icon.png';
import Logo from './logo.png';

export default {
  logo: Logo,
  icon: Icon,
  title: 'Voluntariado empujar',
  location_zoom: 8,
  location_lng: -58,
  location_lat: -34.6,
  name: 'Empujar',
  map_hide: true,
  logo_width: '225px',
  withoutLogin: true,
  community: true,
};
